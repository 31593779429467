<style>
.add {
  @apply px-4 flex items-center font-medium mt-2 cursor-pointer;
  color: var(--primary);
  font-size: 17px;
  letter-spacing: -0.41px;
}
.add:hover {
  color: #479df7;
}
.add-icon {
  @apply mr-1;
}
.error {
  @apply px-4 mt-1 text-xs leading-none;
  color: #c4272a;
}
</style>

<script>
import NumberInput from "./NumberInput.svelte"
import { workflowLang } from "@shared/store/workflowKey"
import { addMoreText } from "@shared/utils/translations"

export let name = undefined
export let label = undefined
export let value = undefined

let _errors
export { _errors as errors }
$: errors = _errors || []
$: hasErrors = errors.length > 0

let values = !value || value.length === 0 ? [""] : value

function addValue(i) {
  values.push("")
  values = values
}

function removeValue(i) {
  values.splice(i, 1)
  values = values
}

function onChange() {
  value = values.filter((x) => !!x)
}

$: values, onChange()
</script>

<div class="w-full mb-6">
  {#each values as value, i}
    <div
      class:mb-6="{i < values.length - 1}"
      class:mb-4="{i === values.length - 1 && !hasErrors}"
    >
      <NumberInput
        name="{`${name}-${i}`}"
        label="{label}"
        hasErrors="{hasErrors}"
        bind:value="{value}"
        icon="{values.length > 1 && 'remove_circle'}"
        iconClickable="{true}"
        on:iconClick="{() => removeValue(i)}"
      />
    </div>
    {#if i === values.length - 1}
      {#if hasErrors}
        <div class="error">{errors[0]}</div>
      {/if}
    {/if}
  {/each}

  <div class="add" on:click="{addValue}">
    <i class="add-icon material-icons">add_circle</i>
    {addMoreText[$workflowLang]}
  </div>
</div>
