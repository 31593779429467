<style>
.input-wrapper {
  @apply w-full border-0 border-b-4 rounded relative;
  background-color: #f3f3f3;
  border-color: #757575;
}
.input-wrapper.focused {
  border-color: var(--primary);
}
.input-wrapper.has-errors {
  border-color: #c4272a;
}
.input {
  @apply w-full bg-transparent border-0 outline-none pt-6 pb-1 text-black text-lg px-4 ease-in duration-200 appearance-none;
  line-height: 1.5rem;
}
.label {
  @apply w-full text-lg pt-4 pb-4 px-4 ease-in duration-200 absolute top-0 truncate rounded-t;
  color: #9b9b9b;
  line-height: 1.25rem;
}
.label.expanded {
  @apply pb-1 pt-2 text-xs leading-none;
}
.label.focused {
  @apply pointer-events-none;
  color: var(--primary);
}
.input.hasLeftIcon,
.label.hasLeftIcon {
  @apply pl-12;
}
.icon,
.left-icon {
  @apply flex justify-center items-center;
  @apply text-2xl;
  @apply absolute top-0;
  @apply w-13 h-full;
}
.icon {
  @apply right-0;
}
.left-icon {
  @apply left-0;
}

.icon.clickable {
  @apply cursor-pointer;
}
.icon.clickable:hover {
  color: #757575;
}
</style>

<script>
import { createEventDispatcher } from "svelte"

export let name = undefined
export let label = undefined
export let value = undefined
export let hasErrors = undefined
export let inputEl = undefined
export let icon = undefined
export let leftIcon = undefined
export let iconClickable = undefined
export let className = ""
let focused = false
let _errors = []

$: expanded = value || focused
$: hasIcon = !!icon
$: hasLeftIcon = !!leftIcon
$: errors = _errors || []
$: hasErrors = errors.length > 0

const dispatch = createEventDispatcher()

function iconClicked() {
  if (iconClickable) dispatch("iconClick")
}

function handleInput(evt) {
  value = evt.target.value
  dispatch("valueChange", { value })
}
</script>

<div
  class="input-wrapper {className}"
  class:focused="{focused}"
  class:has-errors="{hasErrors}"
>
  {#if hasLeftIcon}<i class="left-icon material-icons">{leftIcon}</i>{/if}
  {#if label}
    <label
      for="{name}"
      class="label"
      class:focused="{focused}"
      class:expanded="{expanded}"
      class:hasLeftIcon="{hasLeftIcon}"
    >
      {label}
    </label>
  {/if}
  <input
    class="input"
    name="{name}"
    id="{name}"
    type="number"
    on:focus="{() => (focused = true)}"
    on:blur="{() => (focused = false)}"
    on:accept
    on:complete
    on:input="{handleInput}"
    bind:this="{inputEl}"
    class:hasLeftIcon="{hasLeftIcon}"
    value="{value}"
    step="any"
    min="0"
  />
  {#if hasIcon}
    <i
      class="icon material-icons"
      class:clickable="{iconClickable}"
      on:click="{iconClicked}"
    >
      {icon}
    </i>
  {/if}
  {#if hasErrors}
    <div class="mt-2 text-red-500">{errors[0]}</div>
  {/if}
</div>
